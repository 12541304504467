import { PropsWithChildren } from "react";

export interface SideBySideProps extends PropsWithChildren {
    columns?: number
}

export default function SideBySide ({children, columns} : SideBySideProps) {

    const gCols = columns ?? 2;
    const className = `grid lg:grid-cols-${gCols} gap-4`;

    return  <div className={className}>
        {children}
    </div>


}
import { forwardRef } from "react";
import { bannerVariants, type BannerVariants } from "./banner.styles";

export type BannerRef = React.ElementRef<"div">;

export interface BannerProps
  extends React.ComponentPropsWithoutRef<"div">,
    BannerVariants {}

const Banner = forwardRef<BannerRef, BannerProps>(
  ({ className, ...props }, ref) => {
    return (
      <div {...props} className={bannerVariants({ className })} ref={ref} />
    );
  },
);

Banner.displayName = "Banner";

export { Banner };
